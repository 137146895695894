import './style.scss';

import React, { useMemo, useState } from 'react';
import Select, {
  ActionMeta,
  OnChangeValue,
  StylesConfig,
  components as ReactSelectComponents,
  OptionProps, GroupBase, MultiValueProps,
} from 'react-select';
import { User } from 'types';

export interface UserListProps {
  children?: never;
  users: User[];
  currentUserId?: string,
  onChange: (userIds: string[]) => void;
}

export interface UserOption {
  readonly value: string;
  readonly label: string;
  readonly isFixed?: boolean;
}

interface UserOptionProps {
  user: User;
}

const UserOptionBadge = (props: UserOptionProps) => (
  <div className="user-select-user-badge">
    <img src={props.user.image_32} alt={props.user.name} /> {props.user.name}
  </div>
);

interface OptionPropsWithUser extends OptionProps<UserOption, true, GroupBase<UserOption>> {
  users: User[];
}

const Option = (props: OptionPropsWithUser) => {
  const user = props.users.find((user) => user.id === props.data.value);
  return user ? (
    <ReactSelectComponents.Option {...props}>
      <UserOptionBadge user={user} />
    </ReactSelectComponents.Option>
  ) : null;
};

interface MultiValuePropsWithUser extends MultiValueProps<UserOption, true, GroupBase<UserOption>> {
  users: User[];
}

const MultiValue = (props: MultiValuePropsWithUser) => {
  const user = props.users.find((user) => user.id === props.data.value);
  return user ? (
    <ReactSelectComponents.MultiValue {...props}>
      <UserOptionBadge user={user} />
    </ReactSelectComponents.MultiValue>
  ) : null;
};

const UserList = (props: UserListProps) => {
  const { users, currentUserId } = props;
  const [ values, setValues ] = useState<UserOption[]>(() => {
    const currentUser = users.find((user) => user.id === currentUserId);
    return currentUser ? [{ value: currentUser.id, label: currentUser.name, isFixed: true }] : [];
  });

  const options = useMemo(() => users.map((user) => ({
    value: user.id,
    label: user.name,
    isFixed: user.id === currentUserId,
  } as UserOption)), [ users, currentUserId ]);

  const orderOptions = (values: UserOption[]) => {
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed));
  };

  const styles: StylesConfig<UserOption, true> = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  };

  const onChange = (value: OnChangeValue<UserOption, true>, actionMeta: ActionMeta<UserOption>) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        props.onChange(options?.filter((v) => v.isFixed).map(x => x.value));
        setValues(orderOptions(options?.filter((v) => v.isFixed) || []));
        return;
    }

    props.onChange(value.map(x => x.value));
    setValues(value as unknown as UserOption[]);
  };

  return (
    <Select
      value={values}
      styles={styles}
      options={options}
      components={{
        Option: (props) => Option({ users, ...props }),
        MultiValue: (props) => MultiValue({ users, ...props }),
      }}
      isMulti={true}
      isSearchable={true}
      isClearable={values ? values.some((v: any) => !v.isFixed) : true}
      onChange={onChange}
      className="user-select-container"
      classNamePrefix="user-select"
      placeholder={'Select to compare users...'}
    />
  );
};

export default UserList;
