import './style.scss';

import React from 'react';

export interface SingleStatsProps {
  children?: never;
  number: number;
  text: string;
}

const SingleStats = (props: SingleStatsProps) => {
  const { number, text } = props;
  return (<section className="single-stats"><div><span>{number}</span>{text}</div></section>);
};

export default SingleStats;
