import './style.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';

export interface MenuItem {
  to: string;
  text: string;
}

interface MenuProps {
  children?: never;
  items: MenuItem[];
}

const Menu = (props: MenuProps) => (
  <nav>
    <ul>
      {props.items.map((item) => (<li key={item.to}><NavLink to={item.to}>{item.text}</NavLink></li>))}
    </ul>
  </nav>
);

export default Menu;
