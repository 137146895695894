import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { createCommonAxiosInstance } from 'utils/axiosInstances';
import { CumulativeIndex, Roll, RollGroupedByPoints, RollsReducedByMonth, ScoreboardItem, TopListItem } from 'types';

const baseURL = process.env.REACT_APP_API_URL + '/api';
const axiosInstance = createCommonAxiosInstance({ baseURL });

export const useRolls = (year: string, enabled = true) =>
  useQuery<Roll[], Error, Roll[]>({
    enabled,
    queryKey: ['rolls', { year }],
    queryFn: () => axiosInstance.get(`/v1/rolls/${year}`).then(res => res.data),
  });

export const useRolls20s = (year: string) => {
  const queryInfo = useRolls(year);

  return {
    ...queryInfo,
    data: useMemo(() => queryInfo.data?.filter((roll) => roll.points === 20), [queryInfo.data]),
  };
};

export const useRolls20sByUser = (year: string, userId: string) => {
  const queryInfo = useRolls20s(year);

  return {
    ...queryInfo,
    data: useMemo(() => queryInfo.data?.filter((roll) => roll.userId === userId), [queryInfo.data]),
  };
};

export const useRolls1s = (year: string) => {
  const queryInfo = useRolls(year);

  return {
    ...queryInfo,
    data: useMemo(() => queryInfo.data?.filter((roll) => roll.points === 1), [queryInfo.data]),
  };
};

export const useRolls1sByUser = (year: string, userId: string) => {
  const queryInfo = useRolls1s(year);

  return {
    ...queryInfo,
    data: useMemo(() => queryInfo.data?.filter((roll) => roll.userId === userId), [queryInfo.data]),
  };
};

export const useTop20sRolls = (year: string) =>
  useQuery<TopListItem[], Error, TopListItem[]>({
    queryKey: ['rolls', 'top', { year, points: 20 }],
    queryFn: () => axiosInstance.get(`/v1/rolls/${year}/top20s`).then(res => res.data),
  });

export const useTop1sRolls = (year: string) =>
  useQuery<TopListItem[], Error, TopListItem[]>({
    queryKey: ['rolls', 'top', { year, points: 1 }],
    queryFn: () => axiosInstance.get(`/v1/rolls/${year}/top1s`).then(res => res.data),
  });

export const useScoreboard = (year: string) =>
  useQuery<ScoreboardItem[], Error, ScoreboardItem[]>({
    queryKey: ['rolls', 'scoreboard', { year }],
    queryFn: () => axiosInstance.get(`/v1/rolls/${year}/scoreboard`).then(res => res.data),
  });

export const useRollsByUsers = (year: string, userIds: string | string[] | undefined) => {
  const queryInfo = useRolls(year, userIds !== undefined);
  const ids = Array.isArray(userIds) ? userIds : [ userIds ];

  return {
    ...queryInfo,
    data: useMemo(() => queryInfo.data?.filter((roll) => ids.includes(roll.userId)), [queryInfo.data, userIds]),
  };
};

export const useRollsReducedByMonth = (year: string) =>
  useQuery<RollsReducedByMonth[], Error, RollsReducedByMonth[]>({
    queryKey: ['rolls', 'byMonth', { year, users: 'index' }],
    queryFn: () => axiosInstance.get(`/v1/rolls/${year}/reducedByMonth`).then(res => res.data),
  });

export const useRollsReducedByMonthByUser = (year: string, userIds?: string[]) =>
  useQuery<RollsReducedByMonth[], Error, RollsReducedByMonth[]>({
    enabled: !!userIds,
    queryKey: ['rolls', 'by-month', { year, users: userIds }],
    queryFn: () => axiosInstance.get(`/v1/rolls/${year}/reducedByMonth/${userIds}`).then(res => res.data),
  });

export const useRollsGroupedByPoints = (year: string) =>
  useQuery<RollGroupedByPoints[], Error, RollGroupedByPoints[]>({
    queryKey: ['rolls', 'grouped-by-points', { year }],
    queryFn: () => axiosInstance.get(`/v1/rolls/${year}/groupedByPoints`).then(res => res.data),
  });

export const useRollsCumulativeIndex = (year: string) =>
  useQuery<CumulativeIndex[], Error, CumulativeIndex[]>({
    queryKey: ['rolls', 'cumulative-index', { year }],
    queryFn: () => axiosInstance.get(`/v1/rolls/${year}/cumulativeIndex`).then(res => res.data),
  });
