import './style.scss';

import React, { useMemo, useRef } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { useParams } from 'react-router-dom';
import { useRollsGroupedByPoints } from 'hooks/queries/roll';
import { defaultYear } from 'utils/common';

export interface RollsByPointsProps {
  children?: never;
}

const RollsByPoints = (props: RollsByPointsProps) => {
  const { year = defaultYear } = useParams<'year'>();
  const chartComponent = useRef<HighchartsReact.RefObject>(null);

  const rolls = useRollsGroupedByPoints(year);

  const options = useMemo(() => {
    chartComponent.current?.chart.showLoading();

    const options: Highcharts.Options = {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
      },
      time: {
        useUTC: false,
      },
      title: undefined,
      series: [],
      tooltip: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{y} st',
            style: {
              color: '#FFFFFF',
              fontWeight: 'bold',
              fontSize: '14px',
            },
          },
        },
        column: {
          pointPadding: 0.1,
          groupPadding: 0,
        },
      },
      xAxis: {
        labels: {
          style: {
            color: '#FFFFFF',
            fontSize: '20px',
          },
        },
      },
      yAxis: { visible: false },
    };

    if (rolls.data === undefined) {
      return options;
    }

    chartComponent.current?.chart.hideLoading();

    options.series = [{
      type: 'column',
      data: rolls.data?.map((item) => ([item.points, item.rolls])),
    }];

    return options;
  }, [rolls.data]);

  return (
    <section className={'section section--rolls-by-points'}>
      <h2>Rolls by points</h2>

      <div style={{ marginTop: 16 }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </div>

    </section>
  );
};

export default RollsByPoints;
