import React, { useMemo, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import { Info, UnitLength } from 'luxon';
import { useParams } from 'react-router-dom';
import { colors, defaultYear } from 'utils/common';
import { useRollsReducedByMonth, useRollsReducedByMonthByUser } from 'hooks/queries/roll';
import { useUsersByYear } from 'hooks/queries/user';

export interface PointsByMonthChartProps {
  children?: never;
  selectedUserIds?: string[];
}

const PointsByMonthChart = (props: PointsByMonthChartProps) => {
  const { year = defaultYear } = useParams<'year' | 'userId'>();
  const chartComponent = useRef<HighchartsReact.RefObject>(null);

  HighchartsNoData(Highcharts);

  const users = useUsersByYear(year);
  const chartDataIndex = useRollsReducedByMonth(year);
  const chartDataUsers = useRollsReducedByMonthByUser(year, props.selectedUserIds);
  const numberToMonth = (unit: UnitLength) => (value: number) => Info.months(unit)[value - 1];

  const options = useMemo(() => {
    chartComponent.current?.chart.showLoading();

    const options: Highcharts.Options = {
      chart: { marginTop: 40, backgroundColor: 'transparent' },
      time: { useUTC: false },
      title: undefined,
      colors,
      series: [],
      tooltip: { shared: true },
      yAxis: { title: { text: 'Points' } },
    };

    if (chartDataIndex.data === undefined || users.data === undefined) {
      return options;
    }

    chartComponent.current?.chart.hideLoading();

    options.xAxis = { categories: chartDataIndex.data.map((item) => numberToMonth('short')(item.month)) };

    options.series?.push({
      type: 'spline',
      name: 'Index',
      data: chartDataIndex.data.map((item) => item.index || null) || [],
      dashStyle: 'LongDash',
    });

    props.selectedUserIds?.forEach((userId, index) => {
      options.series?.push({
        type: 'spline',
        name: users.data.find((user) => user.id === userId)?.name,
        data: chartDataUsers.data?.map((item) => item[userId] || null) || [],
      });
    });

    return options;
  }, [chartDataIndex.data, chartDataUsers.data, users.data, props.selectedUserIds]);

  return (
    <section className="section">
      <h2>Points by month</h2>

      <div style={{ marginTop: 16 }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </div>

    </section>
  );
};

export default PointsByMonthChart;
