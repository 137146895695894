import './style.scss';

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ScoreboardItem } from 'types';
import { defaultYear } from 'utils/common';
import ListItem from '../ListItem';

export interface ScoreboardProps {
  children?: never;
  title: string;
  items: ScoreboardItem[];
}

const Scoreboard = (props: ScoreboardProps) => {
  const { title, items } = props;
  const { year = defaultYear } = useParams<'year'>();

  return (
    <section className={'section scoreboard'}>
      <h2>{title}</h2>
      {items.length === 0 && (<p>No rolls so far</p>)}
      {items.map((item, index) => (
        <ListItem key={item.username} className={'zebra'}>
          <div className="scoreboard-item-index">{index + 1}</div>
          <div className="scoreboard-item-image list-item-no-grow"><img src={item.image} alt={item.username} /></div>
          <div className="scoreboard-item-name list-item-grow">
            <Link to={`/user/${item.userId}/${year}`}>{item.username}</Link> has <strong>{item.points}</strong> points <em>({item.rolls} rolls / average: {Math.round(item.average)})</em>
          </div>
        </ListItem>
      ))}
    </section>
  );
};

export default Scoreboard;
