import './style.scss';

import React from 'react';
import { TopListItem } from 'types';
import ListItem from '../ListItem';

export interface TopListProps {
  children?: never;
  title: string;
  items: TopListItem[];
}

const TopList = (props: TopListProps) => {
  const { title, items } = props;

  return (
    <section className={'section top-list'}>
      <h2>{title}</h2>
      {items.length === 0 && (<p>None so far</p>)}
      {items.map((item) => (
        <ListItem key={item.username}>
          <div className="top-list-item-image"><img src={item.image} alt={item.image}/></div>
          <div className="top-list-item-name list-item-grow">{item.username}</div>
          <div className="top-list-item-points">{item.total}</div>
        </ListItem>
      ))}
    </section>
  );
};

export default TopList;
