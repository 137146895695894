import React, { useMemo, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { colors, defaultYear } from 'utils/common';
import { useRollsByUsers, useRollsCumulativeIndex } from 'hooks/queries/roll';
import { useUsersByYear } from 'hooks/queries/user';

export interface CumulativePointsChartProps {
  children?: never;
  selectedUserIds?: string[];
}

const CumulativePointsChart = (props: CumulativePointsChartProps) => {
  const { year = defaultYear } = useParams<'year' | 'userId'>();
  const chartComponent = useRef<HighchartsReact.RefObject>(null);

  HighchartsNoData(Highcharts);

  const users = useUsersByYear(year);
  const rolls = useRollsByUsers(year, props.selectedUserIds);
  const cumulativeIndex = useRollsCumulativeIndex(year);

  const options = useMemo(() => {
    chartComponent.current?.chart.showLoading();

    const options: Highcharts.Options = {
      chart: { marginTop: 40, backgroundColor: 'transparent' },
      time: { useUTC: false },
      title: undefined,
      colors,
      plotOptions: { series: { cumulative: true } },
      rangeSelector: { enabled: false },
      navigator: { enabled: false },
      scrollbar: { enabled: false },
      series: [],
      xAxis: { type: 'datetime' },
      yAxis: { title: { text: 'Points' } },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.cumulativeSum})<br/>',
        changeDecimals: 0,
        valueDecimals: 0,
      },
    };

    if (cumulativeIndex.data === undefined || rolls.data === undefined || users.data === undefined) {
      return options;
    }

    chartComponent.current?.chart.hideLoading();

    options.series?.push({
      type: 'spline',
      name: 'Index',
      data: cumulativeIndex.data.map((item) => [DateTime.fromISO(item.date).set({ hour: 0, minute: 0, second: 0 }).toMillis(), item.index]) || [],
      dashStyle: 'LongDash',
    });

    props.selectedUserIds?.forEach((userId, index) => {
      options.series?.push({
        type: 'spline',
        name: users.data.find((user) => user.id === userId)?.name,
        data: rolls.data?.filter((roll) => roll.userId === userId).map((item) => [DateTime.fromISO(item.date).set({ hour: 0, minute: 0, second: 0 }).toMillis(), item.points]) || [],
      });
    });

    return options;
  }, [cumulativeIndex.data, rolls.data, users.data, props.selectedUserIds]);

  return (
    <section className="section">
      <h2>Cumulative points</h2>

      <div style={{ marginTop: 16 }}>
        <HighchartsReact
          ref={chartComponent}
          highcharts={Highcharts}
          options={options}
          constructorType={'stockChart'}
        />
      </div>

    </section>
  );
};

export default CumulativePointsChart;
