import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  useRollsByUsers,
  useRolls1sByUser,
  useRolls20sByUser,
} from 'hooks/queries/roll';
import { defaultYear, percentage, range } from 'utils/common';
import { User } from 'types';
import { useUser } from 'hooks/queries/user';
import SingleStats from 'components/SingleStats';
import Menu, { MenuItem } from 'components/Menu';
import RollList from 'components/RollList';

export interface UserStatsGuardProps {
  children?: never;
}

const UserStatsGuard = (props: UserStatsGuardProps) => {
  const { userId } = useParams<'userId'>();

  const user = useUser(userId);

  if (user.data !== undefined) {
    return (<UserStats viewUser={user.data}/>);
  }

  return null;
};

interface UserStatsProps {
  children?: never;
  viewUser: User;
}

const UserStats = (props: UserStatsProps) => {
  const { year = defaultYear, userId } = useParams<'year' | 'userId'>();
  const { viewUser } = props;

  const rolls = useRollsByUsers(year, viewUser.id);
  const rolls20s = useRolls20sByUser(year, viewUser.id);
  const rolls1s = useRolls1sByUser(year, viewUser.id);
  const pathPrefix = generatePath('/user/:userId', { userId });

  const menuItems = (): MenuItem[] => {
    const items = [{ to: `/${year}`, text: 'Back to overview' }];
    for (const year of range(2018, DateTime.now().year, 1)) {
      items.push({ to: `${pathPrefix}/${year}`, text: String(year) });
    }
    return items;
  };

  const totalRolls = rolls.data?.length || 0;
  const total20s = rolls20s.data?.length || 0;
  const total1s = rolls1s.data?.length || 0;

  return (
    <>
      <Menu items={menuItems()} />
      <div className={'wrapper wrapper--light-blue'}>
        <div className={'container container--960 user-page-header'}>
          <h1>
            <img src={viewUser.image_192} alt={viewUser.name} />
            {viewUser.name}
          </h1>
        </div>
      </div>
      <div className={'wrapper wrapper--blue'}>
        <div className={'container container--960 year-overview-stats'}>
          <h1>{year}</h1>
          <div className={'single-stats-container'}>
            <SingleStats number={totalRolls} text={'Total rolls'} />
            <SingleStats number={total20s} text={`Total 20s (${percentage(totalRolls, total20s)})`} />
            <SingleStats number={total1s} text={`Total 1s (${percentage(totalRolls, total1s)})`} />
          </div>
        </div>
      </div>

      <div className={'wrapper wrapper--white'}>
        <div className={'container container--960'}>
          <RollList rolls={rolls.data || []} user={viewUser} />
        </div>
      </div>
    </>
  );
};

export default UserStatsGuard;
