import './style.scss';

import React from 'react';

import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { Roll, User } from 'types';
import { defaultYear } from '../../utils/common';
import Date from '../Date';
import ListItem from '../ListItem';
import pluralize from 'pluralize';

export interface RollListProps {
  children?: never;
  rolls: Roll[];
  user: User;
}

const RollList = (props: RollListProps) => {
  const { year = defaultYear } = useParams<'year'>();
  const { rolls, user } = props;

  const isWeekendRoll = (roll: Roll) => [ 6, 7 ].includes(DateTime.fromISO(roll.date).weekday);

  return (
    <section className="roll-list">
      <h2>All {pluralize(user.name)} rolls</h2>
      {rolls.length === 0 && (
        <p>No rolls this year.</p>
      )}
      {rolls.length > 0 && parseInt(year) < 2022 && (
        <div className="date-info">Until 2022 only dates where collected, no times. That is why (00:00:00)</div>
      )}
      {rolls.map((roll) => (
        <ListItem key={roll.id} className={classNames({ 'roll-list-item--warning': isWeekendRoll(roll) })}>
          <div className="list-item-grow">
            <Date date={roll.date} />
          </div>
          {isWeekendRoll(roll) ? (<em className="list-item-grow">This was rolled during a weekend but considered valid.</em>) : null}
          <div>{roll.points}</div>
        </ListItem>
      ))}
    </section>
  );
};

export default RollList;
