import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  useRolls,
  useRolls1s,
  useRolls20s,
  useScoreboard,
  useTop1sRolls,
  useTop20sRolls,
} from 'hooks/queries/roll';
import { useUsersByYear } from 'hooks/queries/user';
import { defaultYear, percentage, range } from 'utils/common';
import Menu, { MenuItem } from 'components/Menu';
import SingleStats from 'components/SingleStats';
import Scoreboard from 'components/Scoreboard';
import TopList from 'components/TopList';
import RollsByPoints from 'components/RollsByPoints';
import CumulativePointsChart from 'components/CumulativePointsChart';
import PointsByMonthChart from 'components/PointsByMonthChart';
import UserList from 'components/UserList';

export interface YearStatsGuardProps {
  children?: never;
}

const YearStatsGuard = (props: YearStatsGuardProps) => {
  const { year = defaultYear } = useParams<'year'>();

  if (parseInt(year) !== Number(year) || parseInt(year) < 2018 || parseInt(year) > DateTime.now().year) {
    return (<>NOT FOUND</>);
  }

  return (<YearStats />);
};

interface YearStatsProps {
  children?: never;
}

const YearStats = (props: YearStatsProps) => {
  const { year = defaultYear } = useParams<'year'>();
  const [ selectedUserIds, setSelectedUserIds ] = useState<string[]>();

  const rolls = useRolls(year);
  const rolls20s = useRolls20s(year);
  const rolls1s = useRolls1s(year);
  const top20s = useTop20sRolls(year);
  const top1s = useTop1sRolls(year);
  const scoreboard = useScoreboard(year);
  const users = useUsersByYear(year);

  const totalRolls = rolls.data?.length || 0;
  const total20s = rolls20s.data?.length || 0;
  const total1s = rolls1s.data?.length || 0;

  const onUserListChange = (userIds: string[]) => setSelectedUserIds(userIds);

  const menuItems = (): MenuItem[] => {
    const items = [];
    for (const year of range(2018, DateTime.now().year, 1)) {
      items.push({ to: `/${year}`, text: String(year) });
    }
    return items;
  };

  return (
    <>
      <Menu items={menuItems()} />

      <div className={'wrapper wrapper--blue'}>
        <div className={'container container--960 year-overview-stats'}>

          <h1>{year}</h1>
          <div className={'single-stats-container'}>
            <SingleStats number={totalRolls} text={'Total rolls'} />
            <SingleStats number={total20s} text={`Total 20s (${percentage(totalRolls, total20s)})`} />
            <SingleStats number={total1s} text={`Total 1s (${percentage(totalRolls, total1s)})`} />
          </div>
        </div>
      </div>

      <div className={'wrapper wrapper--light-gray'}>
        <div className={'container container--960'}>
          <Scoreboard title={'Scoreboard'} items={scoreboard.data || []} />
        </div>
        <div className={'wrapper wrapper--white wrapper--inline'}>
          <div className={'container container--960'}>
            <div className="top-list-container">
              <TopList title={'Most 20s'} items={top20s.data || []} />
              <TopList title={'Most 1s'} items={top1s.data || []} />
            </div>
          </div>
        </div>
      </div>

      <div className={'wrapper wrapper--white'}>
        <div className={'container container--full'}>
          {users.data ? <UserList users={users.data} onChange={onUserListChange}/> : null}
          <CumulativePointsChart selectedUserIds={selectedUserIds} />
          <div style={{ marginTop: '64px' }}>
            <PointsByMonthChart selectedUserIds={selectedUserIds} />
          </div>
        </div>
      </div>

      <div className={'wrapper wrapper--black'}>
        <div className={'container container--full'}>
          <RollsByPoints />
        </div>
      </div>

    </>
  );
};

export default YearStatsGuard;
