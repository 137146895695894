import './style.scss';
import classNames from 'classnames';

import React, { ReactNode } from 'react';
import { HtmlAttributes } from 'types';

export interface ListItemProps extends HtmlAttributes {
  children: ReactNode;
}

const ListItem = (props: ListItemProps) => {
  const { children, className } = props;

  return (<div className={classNames('list-item', className)}>{children}</div>);
};

export default ListItem;
