import React from 'react';
import { DateTime } from 'luxon';

export interface DateProps {
  children?: never;
  date: string;
}

const Date = (props: DateProps) => {
  const { date } = props;

  return (
    <time dateTime={date}>
      { DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
    </time>
  );
};

export default Date;
