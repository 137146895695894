import { DateTime } from 'luxon';
import React from 'react';

import { HtmlAttributes } from 'types';

export const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

export const getHtmlAttributes = (props: HtmlAttributes): { id?: string; style?: React.CSSProperties } =>
  ({ id: props.id });

export const sortByMonth = (list: any[]) => {
  const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
  list.sort((a, b) => {
    return months.indexOf(a.values.Month.displayValue) - months.indexOf(b.values.Month.displayValue);
  });
};

export const defaultYear = String(DateTime.now().year);

export const colors = [
  // 40
  // '#2FC8F7',
  // '#E670E6',
  // '#75BA70',
  // '#B5AA0D',
  // '#D98F45',
  // '#FF6BB5',
  // '#5ABFAE',
  // '#F57C64',
  // '#FF757E',
  // '#9F9F9F',
  // '#77A3FC',
  // 50
  '#2C20D4',
  '#00ACEC',
  '#CC50CC',
  '#C17120',
  '#3DA090',
  '#968D0A',
  '#ED5739',
  '#51A34B',
  '#FF4754',
  '#868686',
  '#E64997',
  // 80
  '#4C85FF',
  '#004480',
  '#7A097A',
  '#820D48',
  '#16453D',
  '#1A4717',
  '#3A3813',
  '#542C04',
  '#751A08',
  '#A30005',
  '#333333',
];

export const getBrowserLocales = (languageCodeOnly = true): string[] | undefined => {
  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map(locale => {
    const trimmedLocale = locale.trim();

    return languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
};

export const percentage = (num: number, part: number) => ((part * 100) / num || 0).toFixed(2) + '%';
