import { useQuery } from 'react-query';
import { createCommonAxiosInstance } from 'utils/axiosInstances';
import { User } from 'types';

const baseURL = process.env.REACT_APP_API_URL + '/api';
const axiosInstance = createCommonAxiosInstance({ baseURL });

export const useUser = (userId?: string) =>
  useQuery<User, Error, User>({
    enabled: !!userId,
    queryKey: ['users', 'details', userId],
    queryFn: () => axiosInstance.get(`/v1/users/${userId}`).then(res => res.data),
  });

export const useUsers = () =>
  useQuery<User[], Error, User[]>({
    queryKey: ['users', 'list' ],
    queryFn: () => axiosInstance.get('/v1/users').then(res => res.data),
  });

export const useUsersByYear = (year: string) =>
  useQuery<User[], Error, User[]>({
    queryKey: ['users', 'list', { year }],
    queryFn: () => axiosInstance.get(`/v1/users?year=${year}`).then(res => res.data),
  });

