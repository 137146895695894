import './index.scss';
import { Settings } from 'luxon';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { getBrowserLocales } from './utils/common';

import View from './view';

const browserLocales = getBrowserLocales();
const browserLocale = browserLocales && browserLocales.length && browserLocales[0];
if (browserLocale)
  Settings.defaultLocale = browserLocale;

const queryClient = new QueryClient();

const Root: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <View />
      <ReactQueryDevtools />
    </BrowserRouter>
  </QueryClientProvider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
