import { DateTime } from 'luxon';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import UserStatsGuard from './UserStats';
import YearStatsGuard from './YearStats';

const View = () => {
  return (
    <>
      <Routes>
        <Route index element={<Navigate replace to={`${DateTime.now().year}`} />} />
        <Route path={':year'} element={<YearStatsGuard />} />

        <Route path={'/user'}>
          <Route index element={<Navigate replace to={`${DateTime.now().year}`} />} />
          <Route path={':userId'} element={<UserStatsGuard />}>
            <Route path={':year'} element={<UserStatsGuard />} />
          </Route>
        </Route>
      </Routes>

      <footer>
        <div>Made with ❤ by Stjerneman</div>
      </footer>
    </>
  );
};

export default View;

